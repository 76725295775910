import './media.scss';

import { loadYoutubeApi } from '../../utilities/js/helper';

class Media {
    constructor (element) {
        this.$media = element;
        this.mediaType = this.$media.getAttribute('data-media');
        this.$video = null;
    }

    initialize () {
        if (this.mediaType === 'youtube') {
            this.$video = this.$media.querySelector('[data-youtube]');
            console.log('youtube');
            import('../../utilities/js/youtube-player').then(({ YoutubePlayer }) => {
                this.initYoutubePlayer(YoutubePlayer);
            });
        }

        if (this.mediaType === 'video') {
            this.$video = this.$media.querySelector('video');
            this.initHTML5Player();
        }
    }

    initYoutubePlayer (YoutubePlayer) {
        console.log('init Youtube player');
        if (this.$video) {
            const youtubeId = this.$video.getAttribute('data-youtube');

            if (youtubeId !== '') {
                const videoPlayer = new YoutubePlayer(this.$video, {
                    silent: !!this.$video.getAttribute('data-muted'),
                    fullscreen: !!this.$video.getAttribute('data-fullscreen'),
                    loop: !!this.$video.getAttribute('data-loop'),
                    root: this.$media,
                    id: youtubeId,
                    observe: true,
                    onCall: () => {
                        this.$media.classList.add('is--shown');
                    },
                    onStart: (videoId) => {
                        this.$media.classList.add('is--shown');
                    },
                    onEnd: (videoId) => {
                        if (videoPlayer.player.video.isMuted() === false) {
                            videoPlayer.destroy();
                            this.$media.classList.remove('is--shown');
                        }
                    },
                    onPause: (videoId, progress) => {
                        console.log('video pause');
                    }
                });
            }
        }
    }

    initHTML5Player () {
        const autoplay = !!this.$video.getAttribute('autoplay');
        const loop = !!this.$video.getAttribute('loop');

        if (autoplay) {
            this.$media.classList.add('is--playing');
            this.$video.load();
        }

        if (!loop) {
            this.$video.addEventListener('ended', () => {
                this.$media.classList.remove('is--playing');
            });
        }
    }
}

export { Media };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $medias = $context.querySelectorAll('[data-media]');
        const $galleryMedias = $context.querySelectorAll('[data-gallery-video]');

        if ($medias.length > 0 || $galleryMedias.length > 0) {
            const waitForConsent = setInterval(() => {
                if (typeof CookieConsent !== 'undefined') {
                    if (CookieConsent.consent.marketing === true) { // eslint-disable-line
                        loadYoutubeApi();

                        window.onYouTubeIframeAPIReady = () => {
                            if ($medias.length > 0) {
                                $medias.forEach((media) => {
                                    const $media = new Media(media);
                                    $media.initialize();
                                });
                            }
                            if ($galleryMedias.length > 0) {
                                $galleryMedias.forEach((media) => {
                                    const $media = new Media(media);
                                    $media.initialize();
                                });
                            }
                        };
                    } else {
                        window.addEventListener('CookiebotOnAccept', () => {
                            if (CookieConsent.consent.marketing) { // eslint-disable-line
                                location.reload();
                            }
                        }, false);
                    }
                    clearInterval(waitForConsent);
                } else {
                    console.log('waiting for CookieContent');
                }
            }, 300);
        }
    }
});
